<template>
  <div style="padding-top: 80px;">
    <a-result
      status="404"
      title="404"
      sub-title="抱歉, 你访问的页面不存在.">
      <template #extra>
        <RouterLink to="/">
          <a-button type="primary">返回首页</a-button>
        </RouterLink>
      </template>
    </a-result>
  </div>
</template>

<script>
export default {
  name: 'Exception404'
}
</script>

<style scoped>
:deep(.ant-result-title){
  color: white;
}
:deep(.ant-result-subtitle){
  color: rgb(255 255 255 / 65%)
}
</style>
